import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, forkJoin } from 'rxjs';
import { KeyValueDataService } from '../../../../database/services/key-value-data.service';
import { QuestionStateDto } from '../../../../model/exam/state/question-state-dto';
import { ExamNavigationService } from '../../../../service/exam-navigation.service';
import { ExamToolsService } from '../../../../service/exam-tool.service';
import { ProtractorService } from '../../../../service/protractor.service';
import { SaveService } from '../../../../service/save.service';
import { MathsTool } from '../../../exam/toolbars/maths-toolbar/classes/maths-tool';
import { ExamToolIdentifier } from '../../../exam/toolbars/maths-toolbar/classes/tool.enum';

@Component({
	selector: 'app-footer',
	templateUrl: './exam-footer.component.html',
	styleUrls: ['./exam-footer.component.scss']
})
export class ExamFooterComponent implements OnInit {

	@Input() public text!: string;
	@Input() public enableFlagBtn: boolean = true;
	@Input() public enableErrorBtn: boolean = true;
	@Input() public nextButtonLabel: string = "Next";
	@Input() public previousButtonLabel: string = "Previous";
	@Input() public isExam: boolean = false;
	@Input() public enablePreviousButton: boolean = true;
	@Input() public enableNextButton: boolean = true;
	@Input() public isMathsExam: boolean = false;
	@Input() public isTutorial: boolean = false;

	@Output() public previousButtonAction = new EventEmitter<void>();
	@Output() public nextButtonAction = new EventEmitter<void>();
	@Output() public endExamAction = new EventEmitter<void>();

	public currentQuestion!: QuestionStateDto;
	public finalQuestion!: QuestionStateDto;
	public examType!: string;
	public ExamToolIdentifier = ExamToolIdentifier;
	public showProtractorSlider: boolean = false;
	public sizeRatio: number = 1;

	public selectedActions?: string[] = [];
	public flaggedQuestions: number[] = [];

	public isSaving: boolean = false;

	public subscriptions: Subscription[] = [];

	constructor(private examNavigationService: ExamNavigationService,
		private keyValueDataService: KeyValueDataService,
		private toolService: ExamToolsService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private saveService: SaveService,
		private protractorService: ProtractorService) {
		this.matIconRegistry.addSvgIcon(
			'Protractor',
			this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svgs/Protractor.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'Ruler',
			this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svgs/Ruler.svg')
		);
	}

	public ngOnInit(): void {
		if (this.isExam) {

			this.subscriptions.push(forkJoin({
				lastQuestion: this.examNavigationService.getLastQuestion(),
				currentQuestion: this.examNavigationService.getCurrentQuestion(),
				flaggedQuestions: this.examNavigationService.getFlaggedQuestionNumbers()
			}).subscribe((data: { lastQuestion: QuestionStateDto, currentQuestion: QuestionStateDto, flaggedQuestions: number[] }) => {
				this.flaggedQuestions = data.flaggedQuestions;
				this.finalQuestion = data.lastQuestion
				this.currentQuestion = data.currentQuestion;
			}));

			this.subscriptions.push(this.examNavigationService.currentQuestionChange$.subscribe((currentQuestion: QuestionStateDto) => {
				this.currentQuestion = currentQuestion;
			}));

			this.subscriptions.push(this.keyValueDataService.getExamType().subscribe((examType: string) => {
				this.examType = examType;
			}));

			this.subscriptions.push(this.examNavigationService.flaggedQuestionsChange$.subscribe((flaggedNumbers: number[]) => {
				this.flaggedQuestions = flaggedNumbers;
			}));

			this.subscriptions.push(this.saveService.isSavingChange$.subscribe((isSaving: boolean) => {
				this.isSaving = isSaving;
			}));

			this.subscriptions.push(this.toolService.currentToolChange$.subscribe((val: MathsTool) => {
				if (val) {
					this.showHideProtractorSlider(val.type, val.enabled);
				}
			}));

		}
		if (this.isTutorial) {
			this.toolService.currentToolChange$.subscribe((val: MathsTool) => {
				if (val) {
					this.showHideProtractorSlider(val.type, val.enabled);
				}
			});
		}
	}

	public questionIsFlagged(): boolean {

		if (this.currentQuestion) {
			return this.flaggedQuestions.indexOf(this.currentQuestion.question.orderIndex) !== -1;
		}
		return false;
	}

	public toggleTool(type: ExamToolIdentifier): void {
		return this.toolService.showHideTool(type);
	}

	public flagCurrentQuestion(): void {
		this.examNavigationService.flagCurrentQuestion();
	}

	public invokeNext(): void {
		this.nextButtonAction.emit();
	}

	public invokeEnd(): void {
		this.endExamAction.emit();
	}

	public invokePrevious(): void {
		this.previousButtonAction.emit();
	}

	public isFinalQuestion(): boolean {

		if (this.currentQuestion && this.finalQuestion && this.currentQuestion.question.orderIndex === this.finalQuestion.question.orderIndex) {
			return true;
		}

		return false;
	}

	public isFirstQuestionInSection(): boolean {

		if (this.currentQuestion && this.currentQuestion.question.first) {
			return true;
		}
		return false;
	}

	public updateProtractorSizeRatio(newSizeRatio: number) {
		this.protractorService.updateProtractorSizeRatio(newSizeRatio);
	}

	private showHideProtractorSlider(type: ExamToolIdentifier, value: boolean): void {

		switch (type) {
			case ExamToolIdentifier.Protractor:
				this.showProtractorSlider = value;
				break;
			default:
		}
	}
}
