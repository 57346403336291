import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription, interval, of } from 'rxjs';
import { map, mergeMap, switchMap, takeWhile } from 'rxjs/operators';
import { QuestionSectionDto } from '../../../../../model/exam/question-section-dto';
import { SignalrService } from '../../../../../service/signalr/signalr.service';
import { SynchroniseService } from '../../../../../service/synchronise.service';
import { TimeService } from '../../../../../service/time.service';

@Component({
	selector: 'app-exam-timer',
	templateUrl: './exam-timer.component.html',
	styleUrls: ['./exam-timer.component.scss']
})

export class ExamTimerComponent implements OnInit, OnDestroy {
	@Input() public candidateExamPin!: string;

	public timeRemaining!: Date;
	public scheduledFinish!: Date;
	public formattedTimeRemaining!: string;
	public timerRunning!: boolean;

	public hoursOutput: string = '00';
	public minutesOutput: string = '00';
	public secondsOutput: string = '00';

	public invigilatorPingCount: number = 0;
	public candidatePingCount: number = 0;

	public subscriptions: Subscription[] = [];

	@Output() public timerRedirectAction: EventEmitter<string> = new EventEmitter<string>();

	constructor(private timeService: TimeService, private signalRService: SignalrService,
		private synchroniseService: SynchroniseService) { }

	public ngOnInit(): void {
		this.subscriptions.push(this.timeService.stopTimerChange$.subscribe(() => {
			this.timerRunning = false;
		}));

		this.signalRService.subscribe('time', (sections: QuestionSectionDto[]) => {
			this.subscriptions.push(this.timeService.addTimeToCurrentSection(sections)
				.pipe(mergeMap((scheduledFinish: Date) => {
					this.scheduledFinish = scheduledFinish;
					return this.timeService.getRemainingDateTime(scheduledFinish);
				}))
				.subscribe((remainingTime: Date) => {
					this.timeRemaining = remainingTime;
				}));
		});

		this.subscriptions.push(this.timeService.getCurrentSectionTime()
			.pipe(mergeMap((scheduledFinish: Date) => {
				this.scheduledFinish = scheduledFinish;
				return this.timeService.getRemainingDateTime(scheduledFinish);
			})
			)
			.subscribe((remainingTime: Date) => {
				this.timeRemaining = remainingTime;
				this.startExamTimer();
			}));
	}

	public ngOnDestroy(): void {
		this.timerRunning = false;
		this.subscriptions.forEach(x => x && x.unsubscribe());
	}

	public startExamTimer(): void {
		this.timerRunning = true;
		this.subscriptions.push(interval(1000)
			.pipe(takeWhile(() => this.timerRunning))
			.pipe(mergeMap(() => this.tick()))
			.subscribe((redirectUrl: string) => {
				if (redirectUrl) {
					this.timerRedirectAction.emit(redirectUrl);
					this.timerRunning = false;
				}
			}));
	}

	public tick(): Observable<string> {
		var timeLeft = this.scheduledFinish.getTime() - new Date().getTime();

		if (timeLeft < 1000) {
			this.formattedTimeRemaining = '00:00';
			return this.stopExamTimer();
		} else {
			this.timeRemaining = new Date(timeLeft);
			const hours = this.timeRemaining.getUTCHours();
			const minutes = this.timeRemaining.getUTCMinutes();
			const seconds = this.timeRemaining.getUTCSeconds();
			this.formatTime(hours, minutes, seconds);

			return of("");
		}
	}

	public stopExamTimer(): Observable<string> {
		this.timerRunning = false;
		return this.timeService.nextBlock().pipe(switchMap((url: string) => this.synchroniseService.calculateChecksum().pipe(map(() => url))));
	}

	public formatTime(hours: number, minutes: number, seconds: number): void {
		this.hoursOutput = hours.toString().padStart(2, '0');
		this.minutesOutput = minutes.toString().padStart(2, '0');
		this.secondsOutput = seconds.toString().padStart(2, '0');
	}
}
